import { uuid } from '../types/types'
import { create } from 'zustand'
import { createUuid } from '../utilities';
import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import type { } from '@redux-devtools/extension'
import moment, {Moment} from 'moment';

export type List = {
  id: uuid
  key: string
  label?: string // label will be changed/derived from the date, like Today, Yesterday, Tomorrow, etc.
  icon?: string
  iconMutable?: boolean
  createdDate?: string  // Moment converted toISOString as local storage can not hold Object
  updatedDate?: string  // Moment converted toISOString as local storage can not hold Object
}

export type ListMap = Record<uuid, List>;
export type ListItemsMap = Record<uuid, uuid[]>;

type ListStore = {
  lists: ListMap
  listItemsMap: ListItemsMap
  setListItemsMap: (listItemsMap: ListItemsMap) => void
  updateListItemsMap: (updater: (currentMap: ListItemsMap) => ListItemsMap) => void; // callback style

  addList: (key: string, label: string) => void
  removeList: (id: string) => void
  updateIcon: (id: string, icon: string) => void
  updateListDate: (id: string, updatedDate: Moment, overwriteCreatedDate?: Moment) => void;
}

/// Day Key starts with this prefix
export const DAY_ = 'DAY_';

/// TODO: how about tomorrow and yesterday? 
const defaultLists: ListMap = {
  '223270cb-72e5-43be-959c-d4e1744a96a6': { id: '223270cb-72e5-43be-959c-d4e1744a96a6', key: 'A', label: 'Important & Urgent', icon: '☀️', },
  '71c4cdf4-0ff3-4722-8069-47a2bb9dd3de': { id: '71c4cdf4-0ff3-4722-8069-47a2bb9dd3de', key: 'B', label: 'Urgent', icon: '⚡', },
  'dc79a944-2a0a-49e5-aa38-19cbca74aabe': { id: 'dc79a944-2a0a-49e5-aa38-19cbca74aabe', key: 'C', label: 'Important', icon: '🌵', },
  'c3565f9e-50e4-4a96-966b-ba95ea2c3292': { id: 'c3565f9e-50e4-4a96-966b-ba95ea2c3292', key: 'D', label: 'Unranked', icon: '🐚', },
  '1a32607c-46f4-49ad-9752-27b51487f60a': { id: '1a32607c-46f4-49ad-9752-27b51487f60a', key: `${DAY_}ZERO`, label: 'Today', icon: '😶', iconMutable: true },
}

const defaultListItemsmap: ListItemsMap = {
  '223270cb-72e5-43be-959c-d4e1744a96a6': [],
  '71c4cdf4-0ff3-4722-8069-47a2bb9dd3de': [],
  'dc79a944-2a0a-49e5-aa38-19cbca74aabe': [],
  'c3565f9e-50e4-4a96-966b-ba95ea2c3292': [],
  '1a32607c-46f4-49ad-9752-27b51487f60a': [],
}

export const useTaskListStore = create<ListStore>()(
  devtools(
    persist(
      (set) => ({
        lists: defaultLists,
        listItemsMap: defaultListItemsmap,

        setListItemsMap: (listItemsMap) => set({ listItemsMap }),
        updateListItemsMap: (updater) => set((state) => ({ 
          listItemsMap: updater(state.listItemsMap),
        })),

        addList: (key, label) => set((state) => {
          const newId = createUuid();
          const now = moment()
          return {
            lists: {
              ...state.lists,
              [newId]: {
                id: newId,
                key,
                label, 
                icon: '😶', 
                iconMutable: true, 
                createdDate: now.toISOString(),
                updatedDate: now.toISOString(),
              }
            },
            listItemsMap: {
              ...state.listItemsMap,
              [newId]: [],
            }
          };
        }),

        removeList: (id) => set((state) => ({
          lists: Object.fromEntries(Object.entries(state.lists).filter(([key]) => key !== id))
        })),

        updateIcon: (id, icon) => set((state) => {
          const updatedLists = { ...state.lists };
          if (updatedLists[id]) {
            updatedLists[id] = { ...updatedLists[id], icon };
          }
          return { lists: updatedLists };
        }),

        updateListDate: (id, updatedDate, overwriteCreatedDate) => set((state) => {
          const updatedLists = { ...state.lists };
          const targetList = updatedLists[id];
          if (targetList) {
            updatedLists[id] = { 
              ...targetList, 
              updatedDate: updatedDate.toISOString(), 
              createdDate: overwriteCreatedDate?.toISOString() || targetList.createdDate 
            };
          }
          return { lists: updatedLists };
        }),

      }),
      {
        name: 'task-list-storage',
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
)