import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from "rehype-raw";
import remarkBreaks from "remark-breaks";

import styles from './TaskItemViewer.module.css'
import classNames from 'classnames';

type Props = {
  value: string;
  placeholder?: string;
  setActive?: (isActive: boolean) => void;
}

export const TaskItemViewer = ({ value, placeholder, setActive }: Props) => {
  const isPlaceholder = !value || value === placeholder;
  return (
    <div className={styles.container}>
      <button className={styles.setActiveButton} onClick={() => setActive?.(true)} />
      <Markdown
        className={classNames(styles.viewerProse, 'prose', { [styles.placeholder]: isPlaceholder })}
        remarkPlugins={[remarkGfm, remarkBreaks]}
        rehypePlugins={[rehypeRaw] as any}
        components={{
          /// Link always open in external window
          a: ({ node, ...props }) => <a target="_blank" rel="noopener noreferrer" {...props} />
        }}
      >
        {value}
      </Markdown>
    </div>
  )
}