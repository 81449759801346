import { useState } from "react";

import { MultipleContainers } from './widgets/MultipleContainers/MultipleContainers.tsx';


import "./App.css";

function App() {

  return (
    <div className="App">
      {/* <h1 className="text-xl font-bold underline">
				Hello world!
			</h1> */}
      <MultipleContainers
        handle
        // itemCount={1}
        // minimal={true}
        vertical={true}
        trashable={true}
        scrollable={true}
      />
    </div>
  );
}

export default App;
