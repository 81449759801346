// This is the main component for the task item
// wraps TaskItemMDXEditor and TaskItemViewer
// and conditionally renders one of them based on the isActive flag

import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { TaskItemMDXEditor } from '../TaskItemMDXEditor/TaskItemMDXEditor';
import { TaskItemViewer } from '../TaskItemViewer/TaskItemViewer';
import { useTaskItemStore } from '../../stores/useTaskItemStore';
import { uuid } from '../../types/types';

import styles from './TaskItem.module.css';

import '@mdxeditor/editor/style.css';
import './prose.css';

type TaskItemProps = {
  id: uuid;
  // isActive?: boolean;
  defaultValue: string;
}

export const TaskItem = React.memo(({ id, defaultValue }: TaskItemProps) => {
  const item = useTaskItemStore(s => s.getItem)(id);
  const activeItemId = useTaskItemStore(s => s.activeItemId);
  const _updateContent = useTaskItemStore(s => s.updateContent);
  const _setActiveItemId = useTaskItemStore(s => s.setActiveItemId);
  const [isEditing, setIsEditing] = useState(false);

  const updateContent = useCallback((markdown: string) => _updateContent(id, markdown), [id]);
  const setActiveItemId = useCallback(() => _setActiveItemId(id), [id]);
  const content = useMemo(() => item?.content || defaultValue, [item?.content, defaultValue]);

  const defaultHeight = 'auto';
  const [height, setHeight] = useState(defaultHeight);
  const refContainer = useRef<HTMLDivElement>(null);
  const refContent = useRef<HTMLDivElement>(null);

  /// Active: current active item, should render TaskItemMDXEditor
  /// Idle: all other items, should render TaskItemViewer
  useEffect(() => {
    if (refContent.current) {
      setHeight(`${refContent.current.scrollHeight}px`);
    }
    console.log('isEditing:', isEditing);
  }, [activeItemId, isEditing]);

  return (
    <div
      ref={refContainer}
      className={`${styles.container}`}
      style={{ height: isEditing ? defaultHeight : height }}
    >
      <div ref={refContent}>
      {
        activeItemId === id ?
          <div className={styles.editorContainer}>
            <TaskItemMDXEditor
              itemId={id}
              value={content}
              placeholder={defaultValue}
              updateContent={updateContent}
              updateIsEditing={setIsEditing}
            />
          </div> :
          <TaskItemViewer
            value={content}
            placeholder={defaultValue}
            setActive={setActiveItemId}
          />
      }
      </div>
    </div>
  )
});
