import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles, codeBlockPlugin, codeMirrorPlugin, CodeToggle, diffSourcePlugin, DiffSourceToggleWrapper,
  headingsPlugin, InsertCodeBlock, linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin, MDXEditor, MDXEditorMethods,
  quotePlugin,
  Separator,
  tablePlugin, toolbarPlugin, UndoRedo,
  useCellValues,
  usePublisher,
  useRealm,
} from '@mdxeditor/editor';
import React, { useEffect } from 'react';

import { linkDialogState$ } from '@mdxeditor/editor'


import classNames from 'classnames';
import { LinkDialogAdvanced, LinkDialogAdvancedRef } from './LinkDialogAdvanced/LinkDialogAdvanced';
import { CreateLink } from './CreateLink/CreateLink';


// import '@mdxeditor/editor/style.css';
// import '../shared/prose.css';

import styles from './TaskItemMDXEditor.module.css';
import { useTaskItemStore } from '../../stores/useTaskItemStore';
import { uuid } from '../../types/types';

type Props = {
  itemId: uuid;
  value: string;
  placeholder?: string;
  setActive?: () => void;
  updateContent: (markdown: string) => void;
  updateIsEditing: (isEditing: boolean) => void;
}

export const TaskItemMDXEditor = ({ itemId, value, placeholder, setActive, updateContent, updateIsEditing }: Props) => {
  const ref = React.useRef<MDXEditorMethods | null>(null);
  const editorContainerRef = React.useRef<HTMLDivElement | null>(null);
  // const toolbarRef = React.useRef<HTMLDivElement | null>(null);
  // const editorlinkDialogAnchor = React.useRef<HTMLDivElement | null>(null);
  const [isFocused, setIsFocused] = React.useState(false);
  const [isFocusedDebounced, setIsFocusedDebounced] = React.useState(false);  
  
  /// Click on toolbar and menu items will trigger onBlur but usually
  /// editor will focus back immediately automatically
  /// use a short delay to prevent the editor from focusing back immediately
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsFocusedDebounced(isFocused);
    }, 100);
    return () => clearTimeout(timeout);
  }, [isFocused]);

  // Move the link dialog anchor under body tag so the popup menu can be correctly positioned
  useEffect(() => {
    console.log('isFocused:', isFocused);
    // On Focus
    if (isFocused) {
      const root = editorContainerRef.current?.querySelector('div.mdxeditor') as HTMLDivElement;
      const target = root.querySelector('div[class*="linkDialogAnchor"]') as HTMLDivElement;
      if (target) {
        // then move it to the end of the body
        document.body.appendChild(target);
        // editorlinkDialogAnchor.current = target;
        // move it back to the editorContainer
        return () => {
          root.appendChild(target);
        }
      }
    } else {
      // On Blur

      /// Need to add a ending space to the editor, and move the cursor to the end, 
      /// so the popover can be hidden
      // ref.current?.setMarkdown(value + ' &#x20;');


      /// Do not immediately hide the dialog, wait for a short while
      /// and if the isFocused is still false, then hide the dialog
      /// This is to prevent the dialog from being hidden when `preview` is switched to `edit`
      const timeout = setTimeout(() => {
        if (!isFocused) {
          linkDialogRef.current?.hide();
          // console.log('hiding link dialog in TaskItemMDXEditor');
        }
      }, 50);
      return () => clearTimeout(timeout);

    }
  }, [isFocused]);

  const linkDialogRef = React.useRef<LinkDialogAdvancedRef | null>(null);

  console.log('TaskItemMDXEditor rendered', itemId);

  return (
    <div 
      ref={editorContainerRef}
      className={classNames(styles.editorContainer, { [styles.isEditorFocused]: isFocusedDebounced })}
      onFocus={() => {
        setIsFocused(true);
        updateIsEditing(true);
        console.log('MDXEditor Container onFocus');
      }}     
    >
      <MDXEditor
        placeholder={placeholder || ''}
        ref={ref}
        className={'editor'}
        contentEditableClassName={'prose'}
        markdown={value === placeholder ? '' : value}
        onBlur={() => {
          setIsFocused(false);
          updateIsEditing(false);
          console.log('MDXEditor onBlur');
        }}
        autoFocus={{ defaultSelection: 'rootEnd' }}
        onChange={(markdown) => {
          console.log('markdown:', markdown);
          updateContent(markdown);
        }}
        plugins={[
          listsPlugin(),
          linkPlugin(),

          linkDialogPlugin({
            LinkDialog: () => {
              // const realm = useRealm()
              // const [linkDialogState] = useCellValues(linkDialogState$);
              // dismissPopup = publishLinkDialogState
              return <LinkDialogAdvanced ref={linkDialogRef} />
            }
          }),


          // tablePlugin(), // does not work somehow
          quotePlugin(),
          headingsPlugin(),

          codeBlockPlugin({ defaultCodeBlockLanguage: 'js' }),
          codeMirrorPlugin({ codeBlockLanguages: { js: 'JavaScript', css: 'CSS' } }),

          // diffSourcePlugin({diffMarkdown: 'An older version', viewMode: 'rich-text'}),
          // todo-pudu: enable the toolbar when focus within
          toolbarPlugin({
            toolbarContents: () => (
              <div className={styles.editorToobar}>
                {/* <UndoRedo /> */}
                <BoldItalicUnderlineToggles />
                <CodeToggle />
                <Separator />
                <CreateLink />
                <Separator />
                <ListsToggle />
                {/* <InsertCodeBlock /> */}
                {/*<DiffSourceToggleWrapper>*/}
                {/*</DiffSourceToggleWrapper>*/}
              </div>
            ),
          }),

          markdownShortcutPlugin(), // This is essential for the markdown to work, and has to be the last plugin
        ]} />

      {/* <button onClick={() => console.log(ref.current?.getMarkdown())}>Get markdown</button> */}
      {/* <button onClick={() => ref.current?.setMarkdown('# 123')}>Set new markdown</button> */}

    </div>
  );
};
