import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal, unstable_batchedUpdates } from 'react-dom';
import {
  CancelDrop,
  closestCenter,
  pointerWithin,
  rectIntersection,
  CollisionDetection,
  DndContext,
  DragOverlay,
  DropAnimation,
  getFirstCollision,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  Modifiers,
  useDroppable,

  useSensors,
  useSensor,
  MeasuringStrategy,
  KeyboardCoordinateGetter,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  SortableContext,
  useSortable,
  arrayMove,
  defaultAnimateLayoutChanges,
  verticalListSortingStrategy,
  SortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { coordinateGetter as multipleContainersCoordinateGetter } from './multipleContainersKeyboardCoordinates.ts';

import { Item, Container, ContainerProps } from '../../components/index.ts';

import { createRange, createUuid } from '../../utilities/index.ts';
import { Arguments } from '@dnd-kit/sortable/dist/hooks/useSortable';
import { Collision } from '@dnd-kit/core/dist/utilities/algorithms/types';

// import { TaskItemMDXEditor } from '../TaskItemMDXEditor/TaskItemMDXEditor.tsx';

// import type {Active, RectMap} from '@dnd-kit/core/dist/store';
// import type {ClientRect, Coordinates} from '@dnd-kit/core/dist/types';

import styles from './MultipleContainers.module.css';
// import { TaskItemCategory } from '../../types/types.ts';
import { useTaskItemStore } from '../../stores/useTaskItemStore.ts';
import { DAY_, List, ListItemsMap, useTaskListStore } from '../../stores/useTaskListStore.ts';
import { uuid } from '../../types/types.ts';


// import { TaskItemMDXEditor } from '../TaskItemMDXEditor/TaskItemMDXEditor.tsx';
// import { TaskItemViewer } from '../TaskItemViewer/TaskItemViewer.tsx';
import { TaskItem } from '../TaskItem/TaskItem.tsx';
import moment from 'moment';


const getDayLabel = (list: List) => {
  const now = moment();
  if (list.key.startsWith(DAY_)) {
    const createdDate = list.createdDate;
    console.log('createdDate', list);
    const dateString = moment(list.createdDate || "").format('DD MMM');
    if (now.isSame(createdDate, 'day')) {
      return 'Today' + ' - ' + dateString;
    } else if (now.subtract(1, 'day').isSame(createdDate, 'day')) {
      return 'Yesterday';
    } else if (now.add(1, 'day').isSame(createdDate, 'day')) {
      return 'Tomorrow';
    }
    return dateString;
  }
  return list.label || '';
}

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

function DroppableContainer(droppableContainerProps: ContainerProps & {
  disabled?: boolean;
  id: uuid;
  items: uuid[];
  style?: React.CSSProperties;
}) {
  const {
    children,
    columns = 1,
    disabled,
    id,
    items,
    style,
    onAddNewItemClick,
    ...props
  } = droppableContainerProps;

  const {
    active,
    attributes,
    isDragging,
    listeners,
    over,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  } as Arguments);
  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== 'container') ||
    items.includes(over.id?.toString())
    : false;

  return (
    <Container
      id={id}
      ref={disabled ? undefined : setNodeRef}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      // showActions={true}
      // showAddNewItemButton={true}
      onAddNewItemClick={onAddNewItemClick}
      columns={columns}
      {...props}
    >
      {children}
    </Container>
  );
}

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};



interface Props {
  adjustScale?: boolean;
  cancelDrop?: CancelDrop;
  columns?: number;
  containerStyle?: React.CSSProperties;
  coordinateGetter?: KeyboardCoordinateGetter;

  getItemStyles?(args: {
    value: uuid;
    index: number;
    overIndex: number;
    isDragging: boolean;
    containerId: uuid;
    isSorting: boolean;
    isDragOverlay: boolean;
  }): React.CSSProperties;

  wrapperStyle?(args: { index: number }): React.CSSProperties;

  // itemCount?: number;
  // items?: Items;
  handle?: boolean;
  renderItem?: any;
  strategy?: SortingStrategy;
  modifiers?: Modifiers;
  minimal?: boolean;
  trashable?: boolean;
  scrollable?: boolean;
  vertical?: boolean;
}

export const TRASH_ID = 'void';
/// PLACEHOLDER_ID is used for adding new items to the list, not used now
// const PLACEHOLDER_ID = 'placeholder';
// const empty: UniqueIdentifier[] = [];

export function MultipleContainers(props: Props) {
  const {
    adjustScale = false,
    cancelDrop,
    columns,
    handle = false,
    // itemCount = 3,
    // items: initialItems,
    containerStyle,
    coordinateGetter = multipleContainersCoordinateGetter,
    getItemStyles = () => ({}),
    wrapperStyle = () => ({}),
    minimal = false,
    modifiers,
    renderItem,
    strategy = verticalListSortingStrategy,
    trashable = false,
    vertical = false,
    scrollable,
  } = props;

  // const [items, setItems] = useState<Items>(
  //   () =>
  //     initialItems ?? {
  //       PRIORITY_A: [],
  //       PRIORITY_B: createRange(itemCount, (index) => `B${index + 1}`),
  //       PRIORITY_C: createRange(itemCount, (index) => `C${index + 1}`),
  //       PRIORITY_D: createRange(itemCount, (index) => `D${index + 1}`),
  //       TODAY: [],
  //     },
  // );

  const lists = useTaskListStore(s => s.lists);
  const items = useTaskListStore(s => s.listItemsMap);
  const setItems = useTaskListStore(s => s.setListItemsMap);
  const updateItems = useTaskListStore(s => s.updateListItemsMap);
  const updateListDate = useTaskListStore(s => s.updateListDate);

  /// TODO: we don't allow dragging/adding/removing containers, so we don't need this
  // const [containers, setContainers] = useState(
  //   Object.keys(items) as uuid[],
  // );
  const containers = Object.keys(items) as uuid[];

  const [activeId, setActiveId] = useState<uuid | null>(null);
  const lastOverId = useRef<uuid | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  const isSortingContainer = activeId ? containers.includes(activeId) : false;

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items,
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
          pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId === TRASH_ID) {
          // If the intersecting droppable is the trash, return early
          // Remove this if you're not using trashable functionality in your app
          return intersections;
        }

        if (overId in items) {
          const containerItems = items[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems.includes(container.id.toString()),
              ),
            })[0]?.id || '';
          }
        }

        lastOverId.current = overId?.toString();

        return [{ id: overId }] as Collision[];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] as Collision[] : [];
    },
    [activeId, items],
  );
  /**
   * Cloned items are used to revert to the state if the drag is cancelled.
   */
  const [clonedItems, setClonedItems] = useState<ListItemsMap | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    }),
  );
  const findContainer = (id: uuid) => {
    if (id in items) {
      return id.toString();
    }

    return Object.keys(items).find((key) => items[key].includes(id.toString()))?.toString();
  };

  const getIndex = (id: uuid) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    const index = items[container].indexOf(id.toString());

    return index;
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  // If Day Zero never been updated, it means that the user is using the app for the first time
  // We need to initialize the list with key `DEFAULT_DAY_ZERO` with the current date for both created and updated dates
  useEffect(() => {
    const defaultDayZeroId = '1a32607c-46f4-49ad-9752-27b51487f60a';  
    const defaultDayZero = lists[defaultDayZeroId];
    const isDefaultDayZeroNeverUpdated = items[defaultDayZeroId]?.length === 0;
    
    if (isDefaultDayZeroNeverUpdated) {
      const now = moment();
      updateListDate(defaultDayZeroId, now, now);
    }

  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment()
      // problem, 
      const today = moment().startOf('day');
      if (now.isAfter(today)) {
        updateListDate('DEFAULT_DAY_ZERO', now);
      }
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        setActiveId(active.id?.toString());
        setClonedItems(items);
      }}
      onDragOver={({ active, over }) => {
        const overId = over?.id;

        if (overId == null || overId === TRASH_ID || active.id in items) {
          return;
        }

        const overContainer = findContainer(overId?.toString());
        const activeContainer = findContainer(active.id?.toString());

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          updateItems((items: ListItemsMap) => {
            const activeItems = items[activeContainer];
            const overItems = items[overContainer];
            const overIndex = overItems.indexOf(overId.toString());
            const activeIndex = activeItems.indexOf(active.id.toString());

            let newIndex: number;

            if (overId in items) {
              newIndex = overItems.length + 1;
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top >
                over.rect.top + over.rect.height;

              const modifier = isBelowOverItem ? 1 : 0;

              newIndex =
                overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            recentlyMovedToNewContainer.current = true;

            return {
              ...items,
              [activeContainer]: items[activeContainer].filter(
                (item) => item !== active.id,
              ),
              [overContainer]: [
                ...items[overContainer].slice(0, newIndex),
                items[activeContainer][activeIndex],
                ...items[overContainer].slice(
                  newIndex,
                  items[overContainer].length,
                ),
              ],
            } as ListItemsMap;
          });
        }
      }}
      onDragEnd={({ active, over, delta }) => {
        console.log('onDragEnd', active.id, over?.id, delta);
        console.log(active.id, items, over?.id);

        /// This branch is executed when the user drags an Container, not an Item
        /// For now we don't allow dragging containers
        // if (active.id in items && over?.id) {
        //   setContainers((containers) => {
        //     const activeIndex = containers.indexOf(active.id?.toString());
        //     const overIndex = containers.indexOf(over.id?.toString());

        //     return arrayMove(containers, activeIndex, overIndex);
        //   });
        // }

        const activeContainer = findContainer(active.id?.toString()) as uuid;

        if (!activeContainer) {
          setActiveId(null);
          return;
        }

        const overId = over?.id;

        if (overId == null) {
          setActiveId(null);
          return;
        }

        if (overId === TRASH_ID) {
          updateItems((items) => ({
            ...items,
            [activeContainer]: items[activeContainer].filter(
              (id) => id !== activeId,
            ),
          }));
          setActiveId(null);
          return;
        }

        // if (overId === PLACEHOLDER_ID) {
        //   const newContainerId = getNextContainerId();
        //   unstable_batchedUpdates(() => {
        //     setContainers((containers) => [...containers, newContainerId]);
        //     updateItems((items) => ({
        //       ...items,
        //       [activeContainer]: items[activeContainer].filter(
        //         (id) => id !== activeId,
        //       ),
        //       [newContainerId]: [active.id?.toString()],
        //     }));
        //     setActiveId(null);
        //   });
        //   return;
        // }

        const overContainer = findContainer(overId?.toString());

        if (overContainer) {
          const activeIndex = items[activeContainer].indexOf(active.id?.toString());
          const overIndex = items[overContainer].indexOf(overId?.toString());

          if (activeIndex !== overIndex) {
            updateItems((items) => ({
              ...items,
              [overContainer]: arrayMove(
                items[overContainer],
                activeIndex,
                overIndex,
              ),
            }));
          }
        }

        setActiveId(null);
      }}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      modifiers={modifiers}
    >
      <div className={styles.container}
      // style={{
      //   // display: 'inline-grid',
      //   // boxSizing: 'border-box',
      //   // padding: 20,
      //   // gridAutoFlow: vertical ? 'row' : 'column',
      // }}
      >
        {/*<SortableContext*/}
        {/*  items={[...containers, PLACEHOLDER_ID]}*/}
        {/*  strategy={*/}
        {/*    vertical*/}
        {/*      ? verticalListSortingStrategy*/}
        {/*      : horizontalListSortingStrategy*/}
        {/*  }*/}
        {/*>*/}
        {containers.map((containerId) => (
          <div className={styles.droppableContainer} key={`dropContainer-${containerId}`}>
            <DroppableContainer
              key={containerId}
              id={containerId}
              label={minimal ? undefined : (getDayLabel(lists?.[containerId]))}
              icon={lists?.[containerId].icon || ''}
              showActions={true}
              iconMutable={lists?.[containerId]?.iconMutable || false}
              //todo-pudu: when there are more than 2 items, here is the best place to change columns to 2
              columns={columns}
              items={items[containerId]}
              scrollable={scrollable}
              style={containerStyle}
              unstyled={minimal}
              // onRemove={() => handleRemove(containerId)}
              onAddNewItemClick={containerId.toString().indexOf('TODAY') !== 0 ?
                () => handleAddNewItem(containerId) : undefined
              }
            >
              <SortableContext items={items[containerId] || []} strategy={strategy}>
                {items[containerId] ? items[containerId].map((value, index) => {
                  return (
                    <SortableItem
                      disabled={isSortingContainer}
                      key={value}
                      id={value}
                      index={index}
                      handle={handle}
                      style={getItemStyles}
                      wrapperStyle={wrapperStyle}
                      renderItem={renderItem}
                      containerId={containerId}
                      getIndex={getIndex}
                    />
                  );
                }) : <div>No items</div>}
              </SortableContext>
            </DroppableContainer>
          </div>
        ))}

        {/*{minimal ? undefined : (*/}
        {/*  <DroppableContainer*/}
        {/*    id={PLACEHOLDER_ID}*/}
        {/*    disabled={isSortingContainer}*/}
        {/*    items={empty}*/}
        {/*    onClick={handleAddColumn}*/}
        {/*    placeholder*/}
        {/*  >*/}
        {/*    + Add column*/}
        {/*  </DroppableContainer>*/}
        {/*)}*/}
        {/*</SortableContext>*/}
      </div>
      {createPortal(
        <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
          {activeId
            ?
            (
              // containers.includes(activeId)
              // ? renderContainerDragOverlay(activeId) :
              renderSortableItemDragOverlay(activeId)
            )
            : null}
        </DragOverlay>,
        document.body,
      )}
      {trashable && activeId && !containers.includes(activeId) ? (
        <Trash id={TRASH_ID} />
      ) : null}
    </DndContext>
  );

  /**
   * When the item is being dragged, we render a drag overlay
   * @param id
   */
  function renderSortableItemDragOverlay(id: uuid) {
    return (
      <Item
        value={id}
        handle={handle}
        style={getItemStyles({
          containerId: findContainer(id) as uuid,
          overIndex: -1,
          index: getIndex(id),
          value: id,
          isSorting: true,
          isDragging: true,
          isDragOverlay: true,
        })}
        color={getColor(id)}
        wrapperStyle={wrapperStyle({ index: 0 })}
        renderItem={renderItem}
        dragOverlay
      />
    );
  }

  /**
   * When the container is being dragged, we render a drag overlay
   * @param containerId
   */
  // function renderContainerDragOverlay(containerId: uuid) {
  //   return (
  //     <Container
  //       id={containerId}
  //       label={`Column ${containerId} Drag Overlay`}
  //       columns={columns}
  //       style={{
  //         height: '100%',
  //       }}
  //       shadow
  //       unstyled={false}
  //     >
  //       {items[containerId].map((item, index) => (
  //         <Item
  //           key={item}
  //           value={item}
  //           handle={handle}
  //           style={getItemStyles({
  //             containerId,
  //             overIndex: -1,
  //             index: getIndex(item),
  //             value: item,
  //             isDragging: false,
  //             isSorting: false,
  //             isDragOverlay: false,
  //           })}
  //           color={getColor(item)}
  //           wrapperStyle={wrapperStyle({ index })}
  //           renderItem={renderItem}
  //         />
  //       ))}
  //     </Container>
  //   );
  // }

  // function handleRemove(containerID: uuid) {
  //   setContainers((containers) =>
  //     containers.filter((id) => id !== containerID),
  //   );
  // }

  function handleAddNewItem(containerID: uuid) {
    // use containerID to find the container and add a new item
    const newItemId = createUuid();
    updateItems((items) => ({
      ...items,
      [containerID]: [...items[containerID], newItemId],
    }));
  }

  // function handleAddColumn() {
  //   const newContainerId = getNextContainerId();

  //   unstable_batchedUpdates(() => {
  //     setContainers((containers) => [...containers, newContainerId]);
  //     updateItems((items) => ({
  //       ...items,
  //       [newContainerId]: [],
  //     }));
  //   });
  // }

  function getNextContainerId() {
    // const containerIds = Object.keys(items);
    // const lastContainerId = containerIds[containerIds.length - 1];

    // return String.fromCharCode(lastContainerId.charCodeAt(0) + 1);
    return createUuid();
  }
}

/// TODO: this color tag can be further used
function getColor(id: uuid) {
  switch (String(id)[0]) {
    case 'A':
      return '#7193f1';
    case 'B':
      return '#ffda6c';
    case 'C':
      return '#00bcd4';
    case 'D':
      return '#ef769f';
  }

  return undefined;
}

function Trash({ id }: { id: uuid }) {
  const { setNodeRef, isOver } = useDroppable({
    id,
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        left: '50%',
        marginLeft: -150,
        bottom: 20,
        width: 300,
        height: 60,
        borderRadius: 5,
        border: '1px solid',
        borderColor: isOver ? 'red' : '#DDD',
      }}
    >
      Drop here to delete
    </div>
  );
}

interface SortableItemProps {
  containerId: uuid;
  id: uuid;
  index: number;
  handle: boolean;
  disabled?: boolean;

  style(args: any): React.CSSProperties;

  getIndex(id: uuid): number;

  renderItem(): React.ReactElement;

  wrapperStyle({ index }: { index: number }): React.CSSProperties;
}

function SortableItem({
  disabled,
  id,
  index,
  handle,
  renderItem,
  style,
  containerId,
  getIndex,
  wrapperStyle,
}: SortableItemProps) {
  const {
    setNodeRef,
    setActivatorNodeRef,
    listeners,
    isDragging,
    isSorting,
    over,
    overIndex,
    transform,
    transition,
  } = useSortable({
    resizeObserverConfig: {},
    id,
  });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;



  /// Todo: save the active item id in the store
  /// so only the active item is rendered using the Editor and all other items are rendered using the Viewer
  // const emptyContent = 'Type to create a new [item](https://www.google.com)';
  const emptyContent = 'Type something...';
  const value = <TaskItem id={id} defaultValue={emptyContent}/>

  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      value={value}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      handleProps={handle ? { ref: setActivatorNodeRef } : undefined}
      index={index}
      wrapperStyle={wrapperStyle({ index })}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(`${over.id}`) : overIndex,
        containerId,
      })}
      color={getColor(id)}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={renderItem}
    />
  );
}

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}
