import { useState } from 'react';
import * as Popover from '@radix-ui/react-popover'
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';

import styles from './EmojiPicker.module.css';
import classNames from 'classnames';
import { useTaskListStore } from '../../stores/useTaskListStore';
import { uuid } from '../../types/types';

const CustomEmojiPicker = ({id, emoji}: {id?: uuid, emoji?: string}) => {
  const updateIcon = useTaskListStore(s => s.updateIcon);
  const [selectedEmoji, setSelectedEmoji] = useState(emoji || '😶');
  const [isIconPickerOpen, setIsIconPickerOpen] = useState(false);

  return (
    <Popover.Root open={isIconPickerOpen}>
      <Popover.Trigger asChild>
        <button
          className={classNames(styles.EmojiButton, { [styles.isFocused]: isIconPickerOpen })}
          onClick={() => setIsIconPickerOpen((state) => !state)}
        >{selectedEmoji}</button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className={styles.popoverContent}>
          <EmojiPicker
            searchDisabled={true}
            emojiStyle={EmojiStyle.APPLE}
            lazyLoadEmojis={true}
            width={270}
            onEmojiClick={(emojiData) => {
              // console.log(emojiData);
              setIsIconPickerOpen(false);
              setSelectedEmoji(emojiData.emoji);

              // Only update if id is provided
              if (id) {
                updateIcon(id, emojiData.emoji);
              }
            }}
          />
          <Popover.Arrow className={styles.popoverArrow} />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

export default CustomEmojiPicker;