import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styles from './ActionDropdown.module.css';
import { useTaskListStore } from '../../stores/useTaskListStore';
import moment from 'moment';

export const ActionDropdown: React.FC = () => {

  // use store to add a new list
  const addList = useTaskListStore(state => state.addList);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className={styles.trigger}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 6.5C4.82843 6.5 5.5 7.17157 5.5 8C5.5 8.82843 4.82843 9.5 4 9.5C3.17157 9.5 2.5 8.82843 2.5 8C2.5 7.17157 3.17157 6.5 4 6.5Z" fill="currentColor" />
          <path d="M8 6.5C8.82843 6.5 9.5 7.17157 9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5Z" fill="currentColor" />
          <path d="M12 6.5C12.8284 6.5 13.5 7.17157 13.5 8C13.5 8.82843 12.8284 9.5 12 9.5C11.1716 9.5 10.5 8.82843 10.5 8C10.5 7.17157 11.1716 6.5 12 6.5Z" fill="currentColor" />
        </svg>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content className={styles.content}>
          <DropdownMenu.Item className={styles.item} onSelect={() => {
            console.log('NewDay')
            const tomorrow = moment().add(1, 'day').startOf('day');
            addList('DAY_' + tomorrow.format('YYYYMMDD'), 'Tomorrow')
          }}>
            Start a new day
          </DropdownMenu.Item>
          <DropdownMenu.Item className={styles.item} onSelect={() => console.log('DrawCard')}>
            Draw a card
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
