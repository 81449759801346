import React, { ForwardedRef, forwardRef } from 'react';
import classNames from 'classnames';

import { Handle, Item, Remove } from '../Item';

import styles from './Container.module.css';
import EmojiPicker from '../EmojiPicker/EmojiPicker';
import { uuid } from '../../types/types';
import { ActionDropdown } from '../ActionDropdown/ActionDropdown';

export interface Props {
  id: uuid;
  children: React.ReactNode;
  columns?: number;
  label?: string;
  icon?: string;
  iconMutable?: boolean;
  style?: React.CSSProperties;
  horizontal?: boolean;
  hover?: boolean;
  handleProps?: React.HTMLAttributes<any>;
  scrollable?: boolean;
  shadow?: boolean;
  placeholder?: boolean;
  unstyled?: boolean;
  showActions?: boolean;

  onClick?(): void;

  onRemove?(): void;

  onAddNewItemClick?(): void; // the add buton click function
}

export const Container = forwardRef<HTMLDivElement, Props>(
  (
    {
      id,
      children,
      columns = 1,
      handleProps,
      horizontal,
      hover,
      onClick,
      onRemove,
      onAddNewItemClick,
      label,
      icon,
      iconMutable,
      placeholder,
      style,
      scrollable,
      shadow,
      unstyled,
      showActions,
      ...props
    }: Props,
    ref,
  ) => {
    const Component = onClick ? 'button' : 'div';

    return (
      <Component
        {...props}
        // @ts-ignore
        ref={ref}
        style={
          {
            ...style,
            '--columns': columns,
          } as React.CSSProperties
        }
        className={classNames(
          styles.Container,
          unstyled && styles.unstyled,
          horizontal && styles.horizontal,
          hover && styles.hover,
          placeholder && styles.placeholder,
          scrollable && styles.scrollable,
          shadow && styles.shadow,
        )}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
      >
        {label ? (
          <div className={styles.Header}>
            {icon ? (
              iconMutable ? (
                <EmojiPicker id={id} emoji={icon} />
              ) : (
                <span className={styles.Icon}>{icon}</span>
              )
            ) : null}
            {label ? <span className={styles.Label}>{label}</span> : null}
            {showActions ? 
              <div className={styles.Actions}>
                {/* Remove is for deleting, not needed now */}
                {/* {onRemove ? <Remove onClick={onRemove} /> : null} */}

                {/* Handle is for dragging & moving, not needed now */}
                {/* <Handle {...handleProps} /> */}

                <ActionDropdown />
              </div> : null}
          </div>
        ) : null}
        {placeholder ? children :
          <ul className={styles.containerList}>
            {children}
            {/*show add new item button when there is already one task in the container*/}
            {onAddNewItemClick && <Item
              value={<span>Add a new item</span>}
              plus={true}
              onClick={onAddNewItemClick}
              style={{ 'color': 'silver' }}
            />}
          </ul>
        }

      </Component>
    );
  },
);
