import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { uuid } from '../types/types.ts';
import { createUuid } from '../utilities';
import { devtools } from 'zustand/middleware'
import type { } from '@redux-devtools/extension'

type ItemState = {
  id: uuid
  title: string // use the first line (and limit to 40 characters) of the content as the title
  content: string // markdown string
  listId: uuid  // each item must belong to a list

  createdDate: Date
  updatedDate?: Date
}

type ItemStore = {
  items: Record<uuid, ItemState>;
  // only active item is rendered using the Editor and all other items are rendered using the Viewer
  activeItemId: uuid | null;
}

type ItemStoreAction = {
  getItem: (id: uuid) => ItemState | null
  updateContent: (id: uuid, content: string, title?: string, listId?: uuid, isActive?: boolean) => void
  setActiveItemId: (id: uuid) => void
}

// Create a store of the type State & Actions
export const useTaskItemStore = create<ItemStore & ItemStoreAction>()(
  devtools(
    persist(
      (set, get) => ({
        items: {},
        activeItemId: null,
        getItem: (id: uuid) => {
          return get().items[id];
        },
        updateContent: (id: uuid, content: string, title?: string, listId?: uuid) => {
          set((state) => {
            const item = state.items[id];
            return {
              items: {
                ...state.items,
                [id]: {
                  ...item,
                  content,
                  title: title || item?.title,
                  listId: listId || item?.listId,
                  updatedDate: new Date(),
                },
              },
            };
          });
        },
        // setActive: (id: uuid, isActive: boolean) => {
        //   set((state) => {
        //     const item = state.items[id];
        //     return {
        //       items: { ...state.items, [id]: { ...item, isActive } },
        //     };
        //   });
        // }
        /// This will set the active item to the given id, and set all other items to inactive
        setActiveItemId: (id: uuid) => {
          set({ activeItemId: id });
        }
      }),
      {
        name: 'task-item-storage',
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
