import { Action, activeEditor$, cancelLinkEdit$, Cell, createActiveEditorSubscription$, currentSelection$, EditLinkDialog, filter, getSelectedNode, getSelectionRectangle, InactiveLinkDialog, inFocus$, IS_APPLE, map, onWindowChange$, PreviewLinkDialog, readOnly$, switchFromPreviewToLinkEdit$, updateLink$, withLatestFrom } from "@mdxeditor/editor"

import {
  $createTextNode,
  $getSelection,
  $insertNodes,
  $isRangeSelection,
  $getRoot,
  $createRangeSelection,
  $createParagraphNode,
  $setSelection,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  KEY_ESCAPE_COMMAND,
  KEY_MODIFIER_COMMAND,
  BLUR_COMMAND,
  RangeSelection
} from 'lexical'
import { $createLinkNode, $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'

function getLinkNodeInSelection(selection: RangeSelection | null) {
  if (!selection) {
    return null
  }
  const node = getSelectedNode(selection)
  if (node === null) {
    return null
  }
  const parent = node.getParent()
  if ($isLinkNode(parent)) {
    return parent
  } else if ($isLinkNode(node)) {
    return node
  }
  return null
}

/**
 * 
 * This part is a clone of the `link-dialog/index.ts` file from the mdxeditor project.
 * Modifications are marked with `/// Modified starts` and `/// Modified ends` comments.
 * 
 * The current state of the link dialog.
 * @group Link Dialog
 */
export const linkDialogState$ = Cell<InactiveLinkDialog | PreviewLinkDialog | EditLinkDialog>({ type: 'inactive' }, (r) => {
  r.pub(createActiveEditorSubscription$, (editor) => {
    return editor.registerCommand(
      KEY_ESCAPE_COMMAND,
      () => {
        const state = r.getValue(linkDialogState$)
        if (state.type === 'preview') {
          r.pub(linkDialogState$, { type: 'inactive' })
          return true
        }
        return false
      },
      COMMAND_PRIORITY_LOW
    )
  })

  r.pub(createActiveEditorSubscription$, (editor) => {
    return editor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (event) => {
        if (event.key === 'k' && (IS_APPLE ? event.metaKey : event.ctrlKey) && !r.getValue(readOnly$)) {
          const selection = $getSelection()
          // we open the dialog if there's an actual selection
          // or if the cursor is inside a link
          if ($isRangeSelection(selection)) {
            r.pub(openLinkEditDialog$)
            event.stopPropagation()
            event.preventDefault()
            return true
          } else {
            return false
          }
        }
        return false
      },
      COMMAND_PRIORITY_HIGH
    )
  })

  /// modified starts
  // const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey)

  r.pub(createActiveEditorSubscription$, (editor) => {
    return editor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (event) => {
        if (event.key?.toLowerCase() === 'enter' && (IS_APPLE ? event.metaKey : event.ctrlKey) && !r.getValue(readOnly$)) {
          // const selection = $getSelection()
          // if ($isRangeSelection(selection)) {
          //   r.pub(openLinkEditDialog$)
          //   event.stopPropagation()
          //   event.preventDefault()
          //   return true
          // } else {
          //   return false
          // }
          console.log('cmd + enter')

          // r.pub(linkDialogState$, { type: 'inactive' })

          editor?.dispatchCommand(BLUR_COMMAND, new FocusEvent('blur'));
          
          event.stopPropagation()
          event.preventDefault()
          return true;
        }
        return false
      },
      COMMAND_PRIORITY_CRITICAL
    )
  })
  /// modified ends

  r.link(
    r.pipe(
      switchFromPreviewToLinkEdit$,
      withLatestFrom(linkDialogState$),
      map(([, state]) => {
        if (state.type === 'preview') {
          return {
            type: 'edit' as const,
            initialUrl: state.url,
            url: state.url,
            title: state.title,
            linkNodeKey: state.linkNodeKey,
            rectangle: state.rectangle
          } as EditLinkDialog
        } else {
          throw new Error('Cannot switch to edit mode when not in preview mode')
        }
      })
    ),
    linkDialogState$
  )

  r.sub(r.pipe(updateLink$, withLatestFrom(activeEditor$, linkDialogState$, currentSelection$)), ([payload, editor, state, selection]) => {
    const url = payload.url?.trim() ?? ''
    const title = payload.title?.trim() ?? ''

    if (url !== '') {
      if (selection?.isCollapsed()) {
        const linkContent = title || url
        editor?.update(
          () => {
            const linkNode = getLinkNodeInSelection(selection)
            if (!linkNode) {
              const node = $createLinkNode(url, { title })
              node.append($createTextNode(linkContent))
              $insertNodes([node])
              node.select()
            } else {
              linkNode.setURL(url)
              linkNode.setTitle(title)
            }
          },
          { discrete: true }
        )
      } else {
        editor?.dispatchCommand(TOGGLE_LINK_COMMAND, { url, title })
      }

      r.pub(linkDialogState$, {
        type: 'preview',
        linkNodeKey: state.linkNodeKey,
        rectangle: state.rectangle,
        title,
        url
      } as PreviewLinkDialog)
    } else {
      if (state.type === 'edit' && state.initialUrl !== '') {
        editor?.dispatchCommand(TOGGLE_LINK_COMMAND, null)
      }
      r.pub(linkDialogState$, {
        type: 'inactive'
      })
    }
  })

  r.link(
    r.pipe(
      cancelLinkEdit$,
      withLatestFrom(linkDialogState$, activeEditor$),
      map(([, state, editor]) => {
        if (state.type === 'edit') {
          editor?.focus()
          if (state.initialUrl === '') {
            return {
              type: 'inactive' as const
            } as InactiveLinkDialog
          } else {
            return {
              type: 'preview' as const,
              url: state.initialUrl,
              linkNodeKey: state.linkNodeKey,
              rectangle: state.rectangle
            } as PreviewLinkDialog
          }
        } else {
          throw new Error('Cannot cancel edit when not in edit mode')
        }
      })
    ),
    linkDialogState$
  )

  r.link(
    r.pipe(
      r.combine(currentSelection$, onWindowChange$),
      /// Modified starts
      withLatestFrom(activeEditor$, linkDialogState$, readOnly$, inFocus$),
      /// Add inFocus$ to the condition
      map(([[selection], activeEditor, _, readOnly, inFocus]) => {

        /// When the editor is not in focus
        if (!inFocus) {

          /// Not needed, keep as reference
          /// To set the selection to the end of the root node
          // activeEditor?.update(() => {
          //   const root = $getRoot();
          //   const selection = $createRangeSelection();
          //   selection.anchor.set(root.getKey(), root.getChildrenSize(), 'element');
          //   selection.focus.set(root.getKey(), root.getChildrenSize(), 'element');
          //   $setSelection(selection);
          // })

          return { type: 'inactive' } as InactiveLinkDialog
        }

        if ($isRangeSelection(selection) && activeEditor && !readOnly) {
          const node = getLinkNodeInSelection(selection)
          if (node) {
            return {
              type: 'preview',
              url: node.getURL(),
              linkNodeKey: node.getKey(),
              title: node.getTitle(),
              rectangle: getSelectionRectangle(activeEditor)
            } as PreviewLinkDialog
          }
        }

        return { type: 'inactive' } as InactiveLinkDialog
        /// Modified ends

      })
    ),
    linkDialogState$
  )
})


/**
 * An action that opens the link dialog.
 * @group Link Dialog
 */
export const openLinkEditDialog$ = Action((r) => {
  r.sub(
    r.pipe(
      openLinkEditDialog$,
      withLatestFrom(currentSelection$, activeEditor$),
      filter(([, selection]) => $isRangeSelection(selection))
    ),
    ([, selection, editor]) => {
      editor?.focus(() => {
        editor.getEditorState().read(() => {
          const node = getLinkNodeInSelection(selection)
          const rectangle = getSelectionRectangle(editor)!
          if (node) {
            r.pub(linkDialogState$, {
              type: 'edit',
              initialUrl: node.getURL(),
              initialTitle: node.getTitle() ?? '',
              url: node.getURL(),
              title: node.getTitle() ?? '',
              linkNodeKey: node.getKey(),
              rectangle
            })
          } else {
            r.pub(linkDialogState$, {
              type: 'edit',
              initialUrl: '',
              initialTitle: '',
              title: '',
              url: '',
              linkNodeKey: '',
              rectangle
            })
          }
        })
      })
    }
  )
})
