import React, {forwardRef} from 'react';
import {Action, ActionProps} from '../Action';

export const Plus = forwardRef<HTMLButtonElement, ActionProps>(
  (props, ref) => {
    return (
      <Action
        ref={ref}
        cursor="pointer"
        data-cypress="draggable-add"
        {...props}
      >
        <svg viewBox="0 0 20 20" width="12">
          <path
            d="M10 2a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2h-6v6a1 1 0 1 1-2 0v-6H3a1 1 0 1 1 0-2h6V3a1 1 0 0 1 1-1z"></path>
        </svg>
      </Action>
    );
  },
);
